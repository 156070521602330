/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Section About Us
\* ------------------------------------------------------------ */

.section-about-us {
	background-size: cover;
	background-position: center center;
	padding: 100px 0 0;
	margin-bottom: 128px;

	h6 {
		margin-bottom: 34px;
		text-transform: uppercase;
	}

	h3 {
		color: #fff;
		margin-bottom: 40px;
	}

	.section__body {
		display: flex;
		flex-direction: row-reverse;
	}

	.cards {
		flex-grow: 1;
		margin-bottom: -128px;
	}

	.section__entry {
		color: #fff;
		width: 265px;
		padding-top: 50px;
		padding-left: 50px;
		margin-right: -100px;
	}

	.shell {
		max-width: 1034px;
	}

	@media (max-width: 1400px) {
		.section__entry {
			margin-right: 0;
		}
	}

	@media #{$tablet-portrait} {
		padding-bottom: 10px;

		.section__body {
			display: block;
		}

		.section__entry {
			width: 100%;
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 50px;
		}
	}

	@media #{$mobile} {
		padding: 50px 0;
		margin-bottom: 78px;

		h6 {
			margin-bottom: 15px;
		}

		h3 {
			margin-bottom: 10px;
		}

		.section__entry {
			padding: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Clients
\* ------------------------------------------------------------ */

.section-clients {
	padding: 173px 0 169px;
	position: relative;

	.text-overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 340px;
		line-height: 270px;
		color: #f7f7f7;
	}

	.list-clients {
		position: relative;
		z-index: 2;
	}

	@media #{$tablet-portrait} {
		.text-overlay {
			font-size: 200px;
		}
	}

	@media #{$mobile} {
		padding: 157px 0 124px;

		.text-overlay {
			transform: translateX(-50%);
			top: 120px;
			font-size: 129px;
			line-height: 1;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Services
\* ------------------------------------------------------------ */

.section-services {
	padding-bottom: 120px;
	h6 {
		color: $dark;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.section__head {
		margin-bottom: 102px;
	}

	@media #{$tablet-portrait} {
		.shell {
			width: 100%;
		}
	}

	@media #{$mobile} {
		padding-bottom: 0;
		.section__head {
			text-align: left;
			margin-bottom: 36px;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-brands
\* ------------------------------------------------------------ */

.section-brands {
	.shell {
		display: flex;
		align-items: flex-start;
	}

	.section__content {
		width: 42%;
		flex-shrink: 0;
		padding-top: 100px;
		padding-right: 166px;
	}

	.section__inner {
		width: auto;
		margin-right: calc(-100vw + 1340px);
		padding-right: calc(100vw - 1340px);
		padding-top: 85px;
		padding-bottom: 85px;
		background: url(../images/patern1.jpg) 0 0 repeat;
	}

	.brands-list {
		margin-left: -75px;
	}

	@media (max-width: 1360px) {
		.section__inner {
			margin-right: -20px;
			padding-right: 10px;
		}
	}

	@media #{$tablet-portrait} {
		.shell {
			display: block;
		}

		.section__content {
			width: 100%;
			padding-right: 0;
			padding-top: 0;
			margin-bottom: 40px;
		}
	}

	@media #{$mobile} {
		background: url(../images/patern1.jpg) 0 0 repeat;
		background-size: cover;
		padding: 80px 0;

		h3 {
			color: $white;
			br {
				display: none;
			}
		}

		p {
			color: $white;
		}

		.section__inner {
			background: none;
			padding: 0;
		}

		.section__actions {
			padding-top: 40px;
		}

		.brands-list {
			margin-left: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section-brands-main
\* ------------------------------------------------------------ */

.section-brands-main {
	position: relative;
	padding: 100px 0;

	&:before {
		position: absolute;
		top: 50px;
		height: calc(100% - 50px);
		right: 0;
		content: '';
		width: 508px;
		background: url(../images/brands-bg.jpg) 0 0 repeat-y;
	}

	.section__head {
		position: relative;
		z-index: 1;
		margin-bottom: 80px;
		h6 {
			margin-bottom: 43px;
		}

		h3 {
			color: #115b3f;
			margin-bottom: 40px;
		}
	}
	.section__body {
		position: relative;
		z-index: 1;
	}

	.section__head-entry {
		max-width: 700px;
		margin: 0 auto;

		p {
			column-count: 2;
			column-gap: 20px;
		}
	}

	@media #{$tablet-portrait} {
		.section__head-entry {
			p {
				column-count: 1;
			}
		}
	}

	@media #{$mobile} {
		padding: 80px 0;

		&:before {
			top: 40px;
			height: calc(100% - 40px);
			width: 125px;
		}

		h6 {
			margin-bottom: 35px;
		}

		.section__head {
			margin-bottom: 40px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Callout Contact
\* ------------------------------------------------------------ */

.section-callout-contact {
	position: relative;
	padding: 180px 0 156px;
	text-align: center;
	background: #fbfbfb;

	&.white {
		background: #fff;
	}

	h6 {
		color: $dark;
		text-transform: uppercase;
		margin-bottom: 12px;
	}

	.shell {
		position: relative;
		z-index: 2;
	}

	.section__content {
		max-width: 566px;
		margin: 0 auto;
	}

	.list-btns {
		max-width: 390px;
		margin: 0 auto;
	}

	.text-overlay {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		color: #f7f7f7;
		width: 100%;
		line-height: 1;
	}

	@media #{$mobile} {
		padding: 100px 0;

		.text-overlay {
			top: 80px;
			transform: translateX(-50%);
			font-size: 129px;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-main-offering
\* ------------------------------------------------------------ */

.section-main-offering {
	padding-top: 100px;
	padding-bottom: 50px;

	h6 {
		color: $dark;
		margin-bottom: 42px;
	}

	.section__head {
		max-width: 1020px;
		margin: 0 auto 60px;
	}

	@media #{$mobile} {
		padding: 80px 0 0;

		h6 {
			margin-bottom: 35px;
		}

		.section__head {
			text-align: left;
			margin-bottom: 30px;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-capabilities
\* ------------------------------------------------------------ */

.section-capabilities {
	background-size: cover;
	background-position: center center;
	padding: 100px 0;

	h3 {
		color: #fff;
		margin-bottom: 20px;
	}

	h6 {
		margin-bottom: 34px;
	}

	.shell {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}

	.section__head {
		width: 417px;
	}

	.section__body {
		width: 628px;
	}

	@media #{$tablet-portrait} {
		.shell {
			display: block;
		}

		.section__body,
		.section__head {
			width: 100%;
		}

		.section__head {
			margin-bottom: 40px;
		}
	}

	@media #{$mobile} {
		padding: 50px 0;

		h6 {
			margin-bottom: 35px;
		}

		.col--1of2 {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
		}

		.section__actions {
			padding-top: 40px;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-testimonials
\* ------------------------------------------------------------ */

.section-testimonials {
	padding: 98px 0 100px;
	background-size: cover;
	background-position: center center;

	.section__head {
		margin-bottom: 43px;
	}

	h6 {
		color: $dark;
	}

	@media #{$mobile} {
		padding: 50px 0;

		.section__head {
			margin-bottom: 35px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Contact
\* ------------------------------------------------------------ */

.section-contact {
	.section__content {
		padding: 100px 0 120px;
	}

	h6 {
		margin-bottom: 42px;
	}

	@media #{$mobile} {
		.section__content {
			padding: 80px 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-content-with-video
\* ------------------------------------------------------------ */

.section-content-with-video {
	h6 {
		margin-bottom: 20px;
	}
	h4 {
		margin-bottom: 20px;
	}

	.section__inner {
		padding: 100px 0;
		background-repeat: repeat;

		.shell {
			display: flex;
			align-items: flex-start;
			position: relative;
			z-index: 3;
		}
	}

	.section__content {
		width: calc(100% - 314px);
		padding-right: 105px;
	}
	.section__aside {
		width: 314px;
		flex-shrink: 0;
		margin-top: -230px;
		background: url(../images/sidebar-patern.jpg) 0 0 repeat;
		padding: 32px 40px 60px;
	}

	@media #{$tablet-portrait} {
		.section__inner {
			.shell {
				display: block;
			}
		}

		.section__content {
			width: 100%;
		}

		.section__aside {
			width: 100%;
			margin-top: 30px;
		}
	}

	@media #{$mobile} {
		.section__inner {
			padding: 80px 0;
		}

		.section__content {
			padding-right: 0;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-solutions
\* ------------------------------------------------------------ */

.section-solutions {
	.section__content {
		padding: 107px 0 232px;
		color: #fff;
	}

	.section__content-inner {
		max-width: 805px;
	}

	.section__inner {
	}

	.articles-excerpt-grid {
		margin-top: -150px;
	}

	@media #{$mobile} {
		.section__content {
			padding: 80px 0;
		}

		.articles-excerpt-grid {
			margin-top: -60px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Gallery
\* ------------------------------------------------------------ */

.section-gallery {
	padding-top: 100px;

	@media #{$mobile} {
		padding-top: 80px;
	}
}

/* ------------------------------------------------------------ *\
	section-history
\* ------------------------------------------------------------ */

.section-history {
	padding-top: 100px;
	color: #fff;
	margin-bottom: 73px;

	h6 {
		margin-bottom: 35px;
	}
	h3 {
		margin-bottom: 40px;
	}

	.section__head {
		max-width: 910px;
	}

	.section__body {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-start;
	}

	.section__video {
		width: 418px;
		flex-shrink: 0;
		margin-bottom: -73px;
	}

	.section__entry {
		padding-left: 74px;

		p {
			column-count: 2;
			column-gap: 20px;
		}
	}

	@media #{$tablet-portrait} {
		padding-bottom: 10px;
		.section__body {
			display: block;
			margin-bottom: -40px;
		}
		.section__video {
			position: relative;
			margin-bottom: -140px;
		}

		.section__entry {
			margin-bottom: 40px;
			padding-left: 0;
			p {
				column-count: 1;
			}
		}
	}

	@media #{$mobile} {
		padding-top: 50px;

		h6 {
			margin-bottom: 15px;
		}

		.section__video {
			width: 100%;
			margin-bottom: -90px;
		}
	}
}

/* ------------------------------------------------------------ *\
	section-culture
\* ------------------------------------------------------------ */

.section-culture {
	padding: 100px 0;

	h6 {
		margin-bottom: 22px;
	}

	.section__head {
		margin-bottom: 50px;
	}

	@media #{$mobile} {
		padding-bottom: 0;
	}
}

/* ------------------------------------------------------------ *\
	section-roots
\* ------------------------------------------------------------ */

.section-roots {
	padding-top: 156px;
	padding-bottom: 168px;

	.shell {
		position: relative;
		display: flex;
		align-items: center;
	}

	.section__decoration {
		position: absolute;
		top: -156px;
		right: -174px;
		z-index: 1;
	}

	.section__image,
	.section__content {
		width: 50%;
		position: relative;
		z-index: 2;
	}
	.section__content {
		padding-right: 105px;
	}

	.section__image img {
		width: 100%;
		height: auto;
	}

	@media #{$tablet-portrait} {
		.shell {
			display: block;
		}

		.section__content {
			width: 100%;
			padding-right: 0;
			margin-bottom: 40px;
		}

		.section__image {
			width: 100%;
		}
	}

	@media #{$mobile} {
		padding: 80px 0;

		.section__head {
			margin-bottom: 30px;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Certification
\* ------------------------------------------------------------ */

.section-certification {
	padding: 100px 0;
	background: #fbfbfb;

	h6 {
		margin-bottom: 12px;
	}

	.section__head {
		max-width: 480px;
		margin: 0 auto 35px;
	}

	@media #{$mobile} {
		padding: 80px 0;
	}
}

/* ------------------------------------------------------------ *\
	Section Awards
\* ------------------------------------------------------------ */

.section-awards {
	padding: 100px 0;

	h6 {
		margin-bottom: 22px;
	}

	.section__head {
		margin-bottom: 40px;
	}

	.section__group + .section__group {
		margin-top: 80px;
	}

	.section__foot {
		padding-top: 80px;
	}

	@media #{$mobile} {
		padding: 80px 0;

		h3 {
			margin-bottom: 20px;
		}
		.section__group + .section__group {
			margin-top: 25px;
		}

		.section__foot {
			padding-top: 25px;
		}
	}
}
