/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {
}

.slider-testimonial {
	.swiper-pagination {
		left: auto;
		right: 0;
		width: auto;
		font-size: 0;
		padding-right: 4px;
	}

	.swiper-pagination-bullet {
		position: relative;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: #888888;
		opacity: 1 !important;
		font-size: 0;
		margin: 0;

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			width: 40px;
			height: 40px;
			border-radius: 50%;
			border: 1px solid #24332d;
			visibility: hidden;
			opacity: 0;
		}
	}

	.swiper-pagination-bullet-active {
		background: #24332d;
		opacity: 1 !important;

		&:before {
			visibility: visible;
			opacity: 1;
		}
	}
	.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0;

		& + .swiper-pagination-bullet {
			margin-left: 26px;
		}
	}

	@media #{$mobile} {
		.swiper-pagination {
			position: static;
			padding: 41px 5px 5px;
			text-align: left;
		}
	}
}
