/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	background: #fff;
	font-family: $font-open;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $dark;
}

.link-with-icon {
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	color: #115b3f;

	span {
		font-weight: 700;
		font-size: 13px;
		letter-spacing: 1.25px;
		text-transform: uppercase;
	}

	i {
		font-size: 0;
	}

	span + i {
		margin-left: 10px;
	}

	&--white {
		color: #fff;
	}
}

a[href^='mailto'] {
	text-decoration: none;
	word-break: break-all;
}

p.small {
	font-size: 14px;
	line-height: 1.71;
}

p {
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}

ol {
	margin-bottom: 40px;
	list-style-position: inside;

	&:last-child {
		margin-bottom: 0;
	}
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&[href^='tel'] {
		text-decoration: none;
	}
}

h1 {
	font-family: $font-bebas;
	font-weight: 700;
	font-size: 212px;
	color: #115b3f;
	line-height: 1;
}

h2 {
	font-family: $font-family-sans-serif;
	font-size: 22px;
	color: #24332d;
	line-height: 1.27;
}

h3 {
	font-family: $font-bebas;
	font-weight: 700;
	font-size: 100px;
	color: #888888;
	line-height: 1;

	&.white {
		color: #fff;
	}
}

h4 {
	font-family: $font-bebas;
	font-weight: 700;
	font-size: 40px;
	line-height: 1.2;
	color: #24332d;
	margin-bottom: 10px;
}

h5 {
	font-family: $font-bebas;
	font-weight: 700;
	font-size: 26px;
	line-height: 1.5;
	letter-spacing: 0.5px;
}

h6 {
	font-size: 22px;
	line-height: 1.27;
	font-family: $font-montserrat;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 0.7);

	&.dark {
		color: $dark;
	}
}

.text-overlay {
	font-family: $font-bebas;
	font-weight: 700;
	font-size: 380px;
	color: #d8cfb9;
	text-align: center;
	mix-blend-mode: multiply;
	margin-bottom: 0;
	width: 100%;

	&--medium {
		font-size: 325px;
	}

	&.white {
		color: #fff;
		mix-blend-mode: normal;
	}
}

@media (max-width: 1400px) {
	.text-overlay {
		font-size: 220px;
	}
}

@media #{$tablet-portrait} {
	h1 {
		font-size: 138px;
	}

	h2 {
	}

	h3 {
		font-size: 82px;
	}

	.text-overlay {
		font-size: 140px;
	}
}

@media #{$mobile} {
	h1 {
		font-size: 64px;
	}

	h2 {
	}

	h3 {
		font-size: 64px;
	}

	h6 {
		font-size: 18px;
	}

	.text-overlay {
		font-size: 64px;
	}
}

@media (max-width: 370px) {
	h3 {
		font-size: 54px;
	}
}
