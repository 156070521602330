/* ------------------------------------------------------------ *\
	Terms
\* ------------------------------------------------------------ */

.terms {
	padding-bottom: 143px;
	padding-top: 95px;

	p + h5 {
		margin-top: 40px;
	}
}
