/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article-excerpt {
	.article__media {
		box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12),
			0 5px 10px 0 rgba(0, 0, 0, 0.14);
	}

	.article__image img {
		width: 100%;
		height: auto;
	}

	.article__content {
		padding: 60px 62px;
	}

	h5 {
		color: $green;
		margin-bottom: 15px;
	}

	@media #{$mobile} {
		.article__content {
			padding: 34px 15px 40px;
		}
	}
}

.articles-excerpt-grid {
	display: flex;

	.article-excerpt {
		width: 33.33%;
	}

	@media #{$tablet-portrait} {
		display: block;
		.article-excerpt {
			width: 100%;
		}
	}
}

/* ------------------------------------------------------------ *\
	article-horizontal
\* ------------------------------------------------------------ */

.article-horizontal {
	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
	h6 {
		margin-bottom: 12px;
	}

	p {
		margin-bottom: 20px;
	}

	.article__media {
		width: 61%;
		flex-shrink: 0;
	}

	.article__content {
		width: 39%;
		padding: 74px 105px 0 0;
	}

	& + .article-horizontal {
		margin-top: 80px;
	}

	@media #{$tablet-portrait} {
		.article__content {
			padding: 0 30px 0 0;
		}
	}

	@media #{$mobile} {
		display: block;

		.article__content,
		.article__media {
			width: 100%;
		}
		.article__content {
			padding: 28px 0 0;
		}
	}
}
