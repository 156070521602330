/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	padding: 12px 25px;
	min-width: 120px;
	background: $green;
	border-radius: 1px;
	font-weight: 700;
	font-size: 13px;
	color: #ffffff;
	letter-spacing: 1.25px;
	text-align: center;
	text-transform: uppercase;
	transition: background 0.4s;

	&:hover {
		background: #0e4d35;
	}

	&--tan {
		background: $tan;
		color: $green;

		&:hover {
			background: $tan-alt;
			background: #ebdfc9;
		}
	}

	&--block {
		display: block;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

/* ------------------------------------------------------------ *\
	btn menu
\* ------------------------------------------------------------ */

.btn-menu {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 26px;

	span {
		position: absolute;
		display: block;
		height: 4px;
		width: 100%;
		border-radius: 4px;
		background: $green;
		transition: top 0.2s 0.25s, opacity 0.2s 0.25s, transform 0.2s 0s;
	}
	span:nth-child(1) {
		top: 0;
	}
	span:nth-child(2) {
		top: 11px;
	}
	span:nth-child(3) {
		top: 22px;
	}
	&--active span {
		transition: background 0.2s, top 0.2s, opacity 0.2s, transform 0.2s 0.25s;
	}

	&--active span:nth-child(3),
	&--active span:nth-child(1) {
		top: 11px;
	}
	&--active span:nth-child(2) {
		opacity: 0;
	}
	&--active span:nth-child(1) {
		transform: rotate(45deg);
	}
	&--active span:nth-child(3) {
		transform: rotate(-45deg);
	}

	@media #{$mobile} {
		height: 14px;
		width: 18px;

		span {
			height: 2px;
			border-radius: 0;
		}

		span:nth-child(2) {
			top: 6px;
		}

		span:nth-child(3) {
			top: 12px;
		}

		&--active span:nth-child(3),
		&--active span:nth-child(1) {
			top: 6px;
		}
	}
}
