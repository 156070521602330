@import url(../vendor/magnific-popup/magnific-popup.css);
@import url(../vendor/swiper/swiper.min.css);
/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Mixins
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Reset
\* ------------------------------------------------------------ */
@import url(_sprite.css);
* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
main {
  display: block; }

template {
  display: none; }

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none; }

img,
iframe,
video,
audio,
object {
  max-width: 100%; }

img,
iframe {
  border: 0 none; }

img {
  height: auto;
  display: inline-block;
  vertical-align: middle; }

b,
strong {
  font-weight: bold; }

address {
  font-style: normal; }

svg:not(:root) {
  overflow: hidden; }

a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='image'],
label[for] {
  cursor: pointer; }

a[href^='tel'],
button[disabled],
input[disabled],
textarea[disabled],
select[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0; }

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea,
a[href^='tel'] {
  appearance: none; }

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  appearance: none; }

textarea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

button,
select {
  text-transform: none; }

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

nav ul,
nav ol {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */
body {
  min-width: 320px;
  background: #fff;
  font-family: "OpenSans", sans-serif;
  font-size: 16px;
  line-height: 1.56;
  color: #24332d; }

.link-with-icon {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #115b3f; }
  .link-with-icon span {
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1.25px;
    text-transform: uppercase; }
  .link-with-icon i {
    font-size: 0; }
  .link-with-icon span + i {
    margin-left: 10px; }
  .link-with-icon--white {
    color: #fff; }

a[href^='mailto'] {
  text-decoration: none;
  word-break: break-all; }

p.small {
  font-size: 14px;
  line-height: 1.71; }

p {
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

ol {
  margin-bottom: 40px;
  list-style-position: inside; }
  ol:last-child {
    margin-bottom: 0; }

a {
  color: inherit;
  text-decoration: underline; }
  a:hover, a[href^='tel'] {
    text-decoration: none; }

h1 {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 212px;
  color: #115b3f;
  line-height: 1; }

h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  color: #24332d;
  line-height: 1.27; }

h3 {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 100px;
  color: #888888;
  line-height: 1; }
  h3.white {
    color: #fff; }

h4 {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  color: #24332d;
  margin-bottom: 10px; }

h5 {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.5;
  letter-spacing: 0.5px; }

h6 {
  font-size: 22px;
  line-height: 1.27;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7); }
  h6.dark {
    color: #24332d; }

.text-overlay {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 380px;
  color: #d8cfb9;
  text-align: center;
  mix-blend-mode: multiply;
  margin-bottom: 0;
  width: 100%; }
  .text-overlay--medium {
    font-size: 325px; }
  .text-overlay.white {
    color: #fff;
    mix-blend-mode: normal; }

@media (max-width: 1400px) {
  .text-overlay {
    font-size: 220px; } }

@media (max-width: 1023px) {
  h1 {
    font-size: 138px; }
  h3 {
    font-size: 82px; }
  .text-overlay {
    font-size: 140px; } }

@media (max-width: 767px) {
  h1 {
    font-size: 64px; }
  h3 {
    font-size: 64px; }
  h6 {
    font-size: 18px; }
  .text-overlay {
    font-size: 64px; } }

@media (max-width: 370px) {
  h3 {
    font-size: 54px; } }

/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */
@font-face {
  font-family: 'BebasNeue';
  src: url("../fonts/BebasNeueBold.woff") format("woff"), url("../fonts/BebasNeueBold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.svg#OpenSans-Light") format("svg"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Italic.eot");
  src: url("../fonts/OpenSans-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Italic.svg#OpenSans-Italic") format("svg"), url("../fonts/OpenSans-Italic.woff") format("woff"), url("../fonts/OpenSans-Italic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'OpenSans';
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat-Bold.eot");
  src: url("../fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg"), url("../fonts/Montserrat-Bold.woff") format("woff"), url("../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */
/*  Clear  */
.clear {
  @util clearfix; }

/*  Notext  */
.notext {
  @util text-hide; }

/*  Hidden  */
[hidden],
.hidden {
  display: none !important; }

/*  Alignleft  */
.alignleft {
  float: left; }

/*  Alignright  */
.alignright {
  float: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*  Disabled  */
[disabled],
.disabled {
  cursor: default; }

/*  Grid  */
.col {
  max-width: 100%;
  flex: 0 0 100%; }
  .cols {
    display: flex;
    flex-flow: row wrap; }
  .col--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }

.cols--large-spacing {
  margin: 0 -30px; }
  .cols--large-spacing .col {
    padding: 0 30px; }

/*  Responsive Helpers  */
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; }
  .visible-xs-block {
    display: block !important; }
  .visible-xs-inline {
    display: inline !important; }
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important; }
  .visible-sm-block {
    display: block !important; }
  .visible-sm-inline {
    display: inline !important; }
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 1024px) and (max-width: 1200px) {
  .hidden-md {
    display: none !important; }
  .visible-md-block {
    display: block !important; }
  .visible-md-inline {
    display: inline !important; }
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1201px) {
  .hidden-lg {
    display: none !important; }
  .visible-lg-block {
    display: block !important; }
  .visible-lg-inline {
    display: inline !important; }
  .visible-lg-inline-block {
    display: inline-block !important; } }

/* ------------------------------------------------------------ *\
	Shadows
\* ------------------------------------------------------------ */
.shadow-sm {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14); }

.shadow-med {
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14); }

.shadow-med-right {
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14); }

.shadow-lg {
  box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14); }

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */
.wrapper {
  overflow: hidden; }

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */
.shell {
  max-width: 1340px;
  padding-right: 10px;
  padding-left: 10px;
  margin: auto; }
  .shell--fluid {
    max-width: none; }
  .shell--small {
    max-width: 825px; }
  .shell--medium {
    max-width: 1014px; }
  @media (max-width: 1023px) {
    .shell {
      width: 85%;
      max-width: 100%; }
      .shell--small {
        width: 100%;
        max-width: 100%; } }
  @media (max-width: 767px) {
    .shell {
      width: 90%;
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px; } }

/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */
.header {
  position: relative;
  background: #d8cfb9;
  background: url(../images/header-bg.png) 0 0 repeat;
  text-align: center;
  z-index: 2; }
  .header .header__aside {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-direction: row-reverse; }
    .header .header__aside .socials {
      padding: 0 20px; }
  .header .logo {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%); }
  .header .shell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .header .btn-menu {
    display: none; }
  @media (max-width: 1023px) {
    .header {
      height: 82px;
      display: flex;
      align-items: center;
      transition: background-image 0.4s;
      border-bottom: 1px solid rgba(36, 51, 45, 0.12);
      background-position: center center; }
      .header .shell {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .header .btn-menu {
        display: block; }
      .header .logo {
        position: static;
        transform: none; }
      .header--nav-open {
        z-index: 9;
        background: url(../images/header-nav-open.png) 0 0 repeat;
        background-position: center center; }
      .header .header__inner {
        position: fixed;
        top: 82px;
        left: 0;
        width: 100%;
        height: calc(100vh - 82px);
        background: url(../images/header-open-bg.png) 0 0 repeat;
        z-index: 9;
        padding: 40px 30px 30px;
        display: flex;
        flex-direction: column;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.4s, opacity 0.4s; }
        .header .header__inner--open {
          visibility: visible;
          opacity: 1; }
      .header .nav {
        height: calc(100vh - 180px);
        max-height: calc(100vh - 180px);
        overflow-y: auto;
        padding-bottom: 20px; }
      .header .header__aside {
        display: block;
        position: static;
        transform: none;
        padding-top: 20px; }
        .header .header__aside .btn {
          width: 100%;
          max-width: 315px;
          margin-bottom: 30px; }
        .header .header__aside .socials ul {
          justify-content: center; } }

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */
.footer {
  position: relative;
  padding: 80px 0 50px;
  background: url(../images/footer-bg.jpg) 0 0 no-repeat;
  background-size: cover;
  background-position: center center; }
  .footer h4 {
    margin-bottom: 14px; }
  .footer .footer__logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9; }
  .footer .footer-cols {
    display: flex; }
  .footer .footer__col {
    width: 25%; }
  @media (max-width: 1023px) {
    .footer .footer-cols {
      flex-wrap: wrap; }
    .footer .footer__col {
      width: 50%; } }
  @media (max-width: 767px) {
    .footer {
      padding: 55px 0 25px; }
      .footer .footer__logo {
        min-width: 275px; }
      .footer .footer__col {
        padding: 25px 0; }
      .footer .footer__col:nth-child(1) {
        order: 3; }
      .footer .footer__col:nth-child(2) {
        order: 2; }
      .footer .footer__col:nth-child(3) {
        order: 0; }
      .footer .footer__col:nth-child(4) {
        order: 1; } }

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */
.btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  appearance: none;
  padding: 12px 25px;
  min-width: 120px;
  background: #115b3f;
  border-radius: 1px;
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1.25px;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.4s; }
  .btn:hover {
    background: #0e4d35; }
  .btn--tan {
    background: #d1c9b4;
    color: #115b3f; }
    .btn--tan:hover {
      background: #cec7b2;
      background: #ebdfc9; }
  .btn--block {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 100%; }

/* ------------------------------------------------------------ *\
	btn menu
\* ------------------------------------------------------------ */
.btn-menu {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px; }
  .btn-menu span {
    position: absolute;
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 4px;
    background: #115b3f;
    transition: top 0.2s 0.25s, opacity 0.2s 0.25s, transform 0.2s 0s; }
  .btn-menu span:nth-child(1) {
    top: 0; }
  .btn-menu span:nth-child(2) {
    top: 11px; }
  .btn-menu span:nth-child(3) {
    top: 22px; }
  .btn-menu--active span {
    transition: background 0.2s, top 0.2s, opacity 0.2s, transform 0.2s 0.25s; }
  .btn-menu--active span:nth-child(3),
  .btn-menu--active span:nth-child(1) {
    top: 11px; }
  .btn-menu--active span:nth-child(2) {
    opacity: 0; }
  .btn-menu--active span:nth-child(1) {
    transform: rotate(45deg); }
  .btn-menu--active span:nth-child(3) {
    transform: rotate(-45deg); }
  @media (max-width: 767px) {
    .btn-menu {
      height: 14px;
      width: 18px; }
      .btn-menu span {
        height: 2px;
        border-radius: 0; }
      .btn-menu span:nth-child(2) {
        top: 6px; }
      .btn-menu span:nth-child(3) {
        top: 12px; }
      .btn-menu--active span:nth-child(3),
      .btn-menu--active span:nth-child(1) {
        top: 6px; } }

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */
input::placeholder {
  color: inherit;
  opacity: 1; }

textarea::placeholder {
  color: inherit;
  opacity: 1; }

input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px #fff inset; }

.form__label {
  display: block;
  font-weight: 700;
  font-size: 10px;
  color: #888888;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 5px; }

.field,
.textarea {
  background: #f5f5f5;
  font-weight: 400;
  font-size: 16px;
  color: #24332d;
  border: none;
  border-radius: 0;
  width: 100%; }
  .field::placeholder,
  .textarea::placeholder {
    color: #24332d; }

.field {
  height: 41px;
  padding: 0 12px; }

.textarea {
  display: block;
  height: 116px;
  padding: 8px 12px; }

.form__actions {
  padding-top: 10px; }

.form__controls {
  margin-bottom: 20px; }

.form__col {
  max-width: 100%;
  flex: 1;
  padding: 0 10px; }
  .form__col--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }

.form__row {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px; }

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	form-contact
\* ------------------------------------------------------------ */
.form-contact {
  padding: 40px 74px 60px;
  background: #fff;
  box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14); }
  .form-contact.pull-top {
    position: relative;
    margin-top: -277px;
    z-index: 3; }
  @media (max-width: 1023px) {
    .form-contact {
      padding: 30px; } }
  @media (max-width: 767px) {
    .form-contact.pull-top {
      padding-bottom: 40px;
      margin: 0 -30px;
      box-shadow: none; }
    .form-contact .form__col {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; } }

/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */
[class^='list-'] {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */
.list-links {
  font-weight: 700;
  font-size: 13px;
  color: #115b3f;
  letter-spacing: 1.25px; }
  .list-links a {
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.4s; }
    .list-links a:hover {
      color: #24332d; }
  .list-links li {
    margin-bottom: 12px; }

/* ------------------------------------------------------------ *\
	List Links Alt
\* ------------------------------------------------------------ */
.list-links-alt {
  margin-bottom: 0; }
  .list-links-alt a {
    display: block;
    font-size: 14px;
    color: #24332d;
    line-height: 1.71;
    text-decoration: none; }
    .list-links-alt a:hover {
      color: #115b3f; }

/* ------------------------------------------------------------ *\
	List Clients
\* ------------------------------------------------------------ */
.list-clients {
  display: flex;
  flex-wrap: wrap; }
  .list-clients li {
    width: 20%;
    text-align: center;
    padding: 38px 0; }
  .list-clients a {
    font-size: 77px;
    line-height: 1;
    color: #115b3f; }
  @media (max-width: 767px) {
    .list-clients li {
      width: 50%; } }

/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */
.list-features {
  margin-bottom: 0; }
  .list-features li {
    display: flex;
    align-items: center; }
  .list-features li + li {
    margin-top: 24px; }
  .list-features span {
    font-weight: 700;
    font-size: 16px;
    color: #24332d;
    letter-spacing: 0.5px;
    line-height: 1.25; }
  .list-features i {
    width: 63px;
    flex-shrink: 0;
    font-size: 0; }

/* ------------------------------------------------------------ *\
	List btns
\* ------------------------------------------------------------ */
.list-btns {
  display: flex;
  justify-content: center;
  align-items: center; }
  .list-btns li {
    width: 50%;
    padding: 0 5px; }
  .list-btns .btn {
    width: 100%; }
  @media (max-width: 767px) {
    .list-btns {
      display: block; }
      .list-btns li {
        width: 100%; }
      .list-btns li + li {
        margin-top: 10px; }
      .list-btns .btn {
        min-width: 180px; } }

/* ------------------------------------------------------------ *\
	list-capabilities
\* ------------------------------------------------------------ */
.list-capabilities a {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.15;
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none; }

.list-capabilities li {
  padding: 13px 0; }

.list-capabilities li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.2); }

/* ------------------------------------------------------------ *\
	list-features-square
\* ------------------------------------------------------------ */
.list-features-square {
  display: flex;
  margin: 0 -5px; }
  .list-features-square h5 {
    margin-bottom: 15px;
    color: #115b3f; }
  .list-features-square li {
    width: calc(33.33% - 10px);
    margin: 0 5px;
    padding: 50px 57px 50px;
    background: url(../images/feature-tile.jpg) 0 0 no-repeat;
    background-size: cover; }
  @media (max-width: 1023px) {
    .list-features-square li {
      padding: 30px 30px 30px; } }
  @media (max-width: 767px) {
    .list-features-square {
      display: block;
      margin: 0 -30px; }
      .list-features-square li {
        padding: 0;
        margin: 0;
        width: 100%;
        padding: 50px 45px; }
      .list-features-square li + li {
        margin-top: 10px; } }

/* ------------------------------------------------------------ *\
	list-certification
\* ------------------------------------------------------------ */
.list-certification {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .list-certification li {
    width: 33.33%;
    padding: 15px 10px;
    text-align: center; }
  @media (max-width: 767px) {
    .list-certification {
      display: block; }
      .list-certification li {
        width: 100%;
        padding: 0; }
      .list-certification li + li {
        margin-top: 40px; } }

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */
.nav > ul {
  display: flex;
  align-items: center; }

.nav > ul > li {
  margin: 0 30px; }

.nav > ul > li > a {
  position: relative;
  display: block;
  padding: 32px 0;
  font-weight: 700;
  font-size: 13px;
  color: #115b3f;
  letter-spacing: 1.25px;
  text-align: center;
  text-transform: uppercase; }

.nav > ul > li > a:hover:after,
.nav .current > a:after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: '';
  width: 100%;
  height: 3px;
  background: #115b3f;
  z-index: 2; }

.nav a {
  text-decoration: none; }

.nav .parent {
  position: relative; }

.nav .dropdown {
  position: absolute;
  left: 0;
  top: calc(100% - 20px);
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 1px;
  width: 220px;
  visibility: hidden;
  opacity: 0;
  z-index: 3; }
  .nav .dropdown a {
    display: block;
    font-weight: 700;
    font-size: 13px;
    color: #115b3f;
    letter-spacing: 1.25px;
    height: 48px;
    line-height: 48px;
    padding: 0 10px 0 30px;
    background: #fff;
    text-align: left; }
    .nav .dropdown a:hover {
      background: #e3e3e3; }

.nav .parent:hover .dropdown,
.nav .dropdown--open .dropdown {
  visibility: visible;
  opacity: 1; }

@media (max-width: 1200px) {
  .nav > ul > li {
    margin: 0 10px; } }

@media (max-width: 1023px) {
  .nav > ul {
    display: block; }
  .nav > ul > li {
    margin: 0; }
  .nav > ul > li > a {
    text-align: left;
    padding: 11px 0;
    font-family: "BebasNeue", sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #24332d;
    line-height: 1; }
    .nav > ul > li > a:after {
      display: none; }
  .nav .parent > a {
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 10px;
    color: #888888;
    letter-spacing: 1px;
    line-height: 16px;
    border-bottom: 1px solid rgba(36, 51, 45, 0.12); }
  .nav .parent svg {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform 0.4s; }
  .nav .dropdown {
    position: static;
    visibility: visible !important;
    opacity: 1 !important;
    background: none;
    border: none;
    width: 100%;
    box-shadow: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s; }
  .nav .dropdown a {
    position: relative;
    background: none;
    padding-left: 30px;
    font-family: "BebasNeue", sans-serif;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: #24332d; }
    .nav .dropdown a:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
      background: #888888;
      width: 20px;
      height: 2px;
      transform: translateY(-50%); }
  .nav .dropdown--open .dropdown {
    max-height: 500px; }
  .nav .dropdown--open > a svg {
    transform: rotate(180deg) translateY(50%); } }

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Section About Us
\* ------------------------------------------------------------ */
.section-about-us {
  background-size: cover;
  background-position: center center;
  padding: 100px 0 0;
  margin-bottom: 128px; }
  .section-about-us h6 {
    margin-bottom: 34px;
    text-transform: uppercase; }
  .section-about-us h3 {
    color: #fff;
    margin-bottom: 40px; }
  .section-about-us .section__body {
    display: flex;
    flex-direction: row-reverse; }
  .section-about-us .cards {
    flex-grow: 1;
    margin-bottom: -128px; }
  .section-about-us .section__entry {
    color: #fff;
    width: 265px;
    padding-top: 50px;
    padding-left: 50px;
    margin-right: -100px; }
  .section-about-us .shell {
    max-width: 1034px; }
  @media (max-width: 1400px) {
    .section-about-us .section__entry {
      margin-right: 0; } }
  @media (max-width: 1023px) {
    .section-about-us {
      padding-bottom: 10px; }
      .section-about-us .section__body {
        display: block; }
      .section-about-us .section__entry {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 50px; } }
  @media (max-width: 767px) {
    .section-about-us {
      padding: 50px 0;
      margin-bottom: 78px; }
      .section-about-us h6 {
        margin-bottom: 15px; }
      .section-about-us h3 {
        margin-bottom: 10px; }
      .section-about-us .section__entry {
        padding: 0; } }

/* ------------------------------------------------------------ *\
	Section Clients
\* ------------------------------------------------------------ */
.section-clients {
  padding: 173px 0 169px;
  position: relative; }
  .section-clients .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 340px;
    line-height: 270px;
    color: #f7f7f7; }
  .section-clients .list-clients {
    position: relative;
    z-index: 2; }
  @media (max-width: 1023px) {
    .section-clients .text-overlay {
      font-size: 200px; } }
  @media (max-width: 767px) {
    .section-clients {
      padding: 157px 0 124px; }
      .section-clients .text-overlay {
        transform: translateX(-50%);
        top: 120px;
        font-size: 129px;
        line-height: 1; } }

/* ------------------------------------------------------------ *\
	Section Services
\* ------------------------------------------------------------ */
.section-services {
  padding-bottom: 120px; }
  .section-services h6 {
    color: #24332d;
    text-transform: uppercase;
    margin-bottom: 0; }
  .section-services .section__head {
    margin-bottom: 102px; }
  @media (max-width: 1023px) {
    .section-services .shell {
      width: 100%; } }
  @media (max-width: 767px) {
    .section-services {
      padding-bottom: 0; }
      .section-services .section__head {
        text-align: left;
        margin-bottom: 36px; } }

/* ------------------------------------------------------------ *\
	section-brands
\* ------------------------------------------------------------ */
.section-brands .shell {
  display: flex;
  align-items: flex-start; }

.section-brands .section__content {
  width: 42%;
  flex-shrink: 0;
  padding-top: 100px;
  padding-right: 166px; }

.section-brands .section__inner {
  width: auto;
  margin-right: calc(-100vw + 1340px);
  padding-right: calc(100vw - 1340px);
  padding-top: 85px;
  padding-bottom: 85px;
  background: url(../images/patern1.jpg) 0 0 repeat; }

.section-brands .brands-list {
  margin-left: -75px; }

@media (max-width: 1360px) {
  .section-brands .section__inner {
    margin-right: -20px;
    padding-right: 10px; } }

@media (max-width: 1023px) {
  .section-brands .shell {
    display: block; }
  .section-brands .section__content {
    width: 100%;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 40px; } }

@media (max-width: 767px) {
  .section-brands {
    background: url(../images/patern1.jpg) 0 0 repeat;
    background-size: cover;
    padding: 80px 0; }
    .section-brands h3 {
      color: #fff; }
      .section-brands h3 br {
        display: none; }
    .section-brands p {
      color: #fff; }
    .section-brands .section__inner {
      background: none;
      padding: 0; }
    .section-brands .section__actions {
      padding-top: 40px; }
    .section-brands .brands-list {
      margin-left: 0; } }

/* ------------------------------------------------------------ *\
	Section-brands-main
\* ------------------------------------------------------------ */
.section-brands-main {
  position: relative;
  padding: 100px 0; }
  .section-brands-main:before {
    position: absolute;
    top: 50px;
    height: calc(100% - 50px);
    right: 0;
    content: '';
    width: 508px;
    background: url(../images/brands-bg.jpg) 0 0 repeat-y; }
  .section-brands-main .section__head {
    position: relative;
    z-index: 1;
    margin-bottom: 80px; }
    .section-brands-main .section__head h6 {
      margin-bottom: 43px; }
    .section-brands-main .section__head h3 {
      color: #115b3f;
      margin-bottom: 40px; }
  .section-brands-main .section__body {
    position: relative;
    z-index: 1; }
  .section-brands-main .section__head-entry {
    max-width: 700px;
    margin: 0 auto; }
    .section-brands-main .section__head-entry p {
      column-count: 2;
      column-gap: 20px; }
  @media (max-width: 1023px) {
    .section-brands-main .section__head-entry p {
      column-count: 1; } }
  @media (max-width: 767px) {
    .section-brands-main {
      padding: 80px 0; }
      .section-brands-main:before {
        top: 40px;
        height: calc(100% - 40px);
        width: 125px; }
      .section-brands-main h6 {
        margin-bottom: 35px; }
      .section-brands-main .section__head {
        margin-bottom: 40px; } }

/* ------------------------------------------------------------ *\
	Section Callout Contact
\* ------------------------------------------------------------ */
.section-callout-contact {
  position: relative;
  padding: 180px 0 156px;
  text-align: center;
  background: #fbfbfb; }
  .section-callout-contact.white {
    background: #fff; }
  .section-callout-contact h6 {
    color: #24332d;
    text-transform: uppercase;
    margin-bottom: 12px; }
  .section-callout-contact .shell {
    position: relative;
    z-index: 2; }
  .section-callout-contact .section__content {
    max-width: 566px;
    margin: 0 auto; }
  .section-callout-contact .list-btns {
    max-width: 390px;
    margin: 0 auto; }
  .section-callout-contact .text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #f7f7f7;
    width: 100%;
    line-height: 1; }
  @media (max-width: 767px) {
    .section-callout-contact {
      padding: 100px 0; }
      .section-callout-contact .text-overlay {
        top: 80px;
        transform: translateX(-50%);
        font-size: 129px; } }

/* ------------------------------------------------------------ *\
	section-main-offering
\* ------------------------------------------------------------ */
.section-main-offering {
  padding-top: 100px;
  padding-bottom: 50px; }
  .section-main-offering h6 {
    color: #24332d;
    margin-bottom: 42px; }
  .section-main-offering .section__head {
    max-width: 1020px;
    margin: 0 auto 60px; }
  @media (max-width: 767px) {
    .section-main-offering {
      padding: 80px 0 0; }
      .section-main-offering h6 {
        margin-bottom: 35px; }
      .section-main-offering .section__head {
        text-align: left;
        margin-bottom: 30px; } }

/* ------------------------------------------------------------ *\
	section-capabilities
\* ------------------------------------------------------------ */
.section-capabilities {
  background-size: cover;
  background-position: center center;
  padding: 100px 0; }
  .section-capabilities h3 {
    color: #fff;
    margin-bottom: 20px; }
  .section-capabilities h6 {
    margin-bottom: 34px; }
  .section-capabilities .shell {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .section-capabilities .section__head {
    width: 417px; }
  .section-capabilities .section__body {
    width: 628px; }
  @media (max-width: 1023px) {
    .section-capabilities .shell {
      display: block; }
    .section-capabilities .section__body,
    .section-capabilities .section__head {
      width: 100%; }
    .section-capabilities .section__head {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .section-capabilities {
      padding: 50px 0; }
      .section-capabilities h6 {
        margin-bottom: 35px; }
      .section-capabilities .col--1of2 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%; }
      .section-capabilities .section__actions {
        padding-top: 40px; } }

/* ------------------------------------------------------------ *\
	section-testimonials
\* ------------------------------------------------------------ */
.section-testimonials {
  padding: 98px 0 100px;
  background-size: cover;
  background-position: center center; }
  .section-testimonials .section__head {
    margin-bottom: 43px; }
  .section-testimonials h6 {
    color: #24332d; }
  @media (max-width: 767px) {
    .section-testimonials {
      padding: 50px 0; }
      .section-testimonials .section__head {
        margin-bottom: 35px; } }

/* ------------------------------------------------------------ *\
	Section Contact
\* ------------------------------------------------------------ */
.section-contact .section__content {
  padding: 100px 0 120px; }

.section-contact h6 {
  margin-bottom: 42px; }

@media (max-width: 767px) {
  .section-contact .section__content {
    padding: 80px 0; } }

/* ------------------------------------------------------------ *\
	section-content-with-video
\* ------------------------------------------------------------ */
.section-content-with-video h6 {
  margin-bottom: 20px; }

.section-content-with-video h4 {
  margin-bottom: 20px; }

.section-content-with-video .section__inner {
  padding: 100px 0;
  background-repeat: repeat; }
  .section-content-with-video .section__inner .shell {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 3; }

.section-content-with-video .section__content {
  width: calc(100% - 314px);
  padding-right: 105px; }

.section-content-with-video .section__aside {
  width: 314px;
  flex-shrink: 0;
  margin-top: -230px;
  background: url(../images/sidebar-patern.jpg) 0 0 repeat;
  padding: 32px 40px 60px; }

@media (max-width: 1023px) {
  .section-content-with-video .section__inner .shell {
    display: block; }
  .section-content-with-video .section__content {
    width: 100%; }
  .section-content-with-video .section__aside {
    width: 100%;
    margin-top: 30px; } }

@media (max-width: 767px) {
  .section-content-with-video .section__inner {
    padding: 80px 0; }
  .section-content-with-video .section__content {
    padding-right: 0; } }

/* ------------------------------------------------------------ *\
	section-solutions
\* ------------------------------------------------------------ */
.section-solutions .section__content {
  padding: 107px 0 232px;
  color: #fff; }

.section-solutions .section__content-inner {
  max-width: 805px; }

.section-solutions .articles-excerpt-grid {
  margin-top: -150px; }

@media (max-width: 767px) {
  .section-solutions .section__content {
    padding: 80px 0; }
  .section-solutions .articles-excerpt-grid {
    margin-top: -60px; } }

/* ------------------------------------------------------------ *\
	Section Gallery
\* ------------------------------------------------------------ */
.section-gallery {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .section-gallery {
      padding-top: 80px; } }

/* ------------------------------------------------------------ *\
	section-history
\* ------------------------------------------------------------ */
.section-history {
  padding-top: 100px;
  color: #fff;
  margin-bottom: 73px; }
  .section-history h6 {
    margin-bottom: 35px; }
  .section-history h3 {
    margin-bottom: 40px; }
  .section-history .section__head {
    max-width: 910px; }
  .section-history .section__body {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start; }
  .section-history .section__video {
    width: 418px;
    flex-shrink: 0;
    margin-bottom: -73px; }
  .section-history .section__entry {
    padding-left: 74px; }
    .section-history .section__entry p {
      column-count: 2;
      column-gap: 20px; }
  @media (max-width: 1023px) {
    .section-history {
      padding-bottom: 10px; }
      .section-history .section__body {
        display: block;
        margin-bottom: -40px; }
      .section-history .section__video {
        position: relative;
        margin-bottom: -140px; }
      .section-history .section__entry {
        margin-bottom: 40px;
        padding-left: 0; }
        .section-history .section__entry p {
          column-count: 1; } }
  @media (max-width: 767px) {
    .section-history {
      padding-top: 50px; }
      .section-history h6 {
        margin-bottom: 15px; }
      .section-history .section__video {
        width: 100%;
        margin-bottom: -90px; } }

/* ------------------------------------------------------------ *\
	section-culture
\* ------------------------------------------------------------ */
.section-culture {
  padding: 100px 0; }
  .section-culture h6 {
    margin-bottom: 22px; }
  .section-culture .section__head {
    margin-bottom: 50px; }
  @media (max-width: 767px) {
    .section-culture {
      padding-bottom: 0; } }

/* ------------------------------------------------------------ *\
	section-roots
\* ------------------------------------------------------------ */
.section-roots {
  padding-top: 156px;
  padding-bottom: 168px; }
  .section-roots .shell {
    position: relative;
    display: flex;
    align-items: center; }
  .section-roots .section__decoration {
    position: absolute;
    top: -156px;
    right: -174px;
    z-index: 1; }
  .section-roots .section__image,
  .section-roots .section__content {
    width: 50%;
    position: relative;
    z-index: 2; }
  .section-roots .section__content {
    padding-right: 105px; }
  .section-roots .section__image img {
    width: 100%;
    height: auto; }
  @media (max-width: 1023px) {
    .section-roots .shell {
      display: block; }
    .section-roots .section__content {
      width: 100%;
      padding-right: 0;
      margin-bottom: 40px; }
    .section-roots .section__image {
      width: 100%; } }
  @media (max-width: 767px) {
    .section-roots {
      padding: 80px 0; }
      .section-roots .section__head {
        margin-bottom: 30px; } }

/* ------------------------------------------------------------ *\
	Section Certification
\* ------------------------------------------------------------ */
.section-certification {
  padding: 100px 0;
  background: #fbfbfb; }
  .section-certification h6 {
    margin-bottom: 12px; }
  .section-certification .section__head {
    max-width: 480px;
    margin: 0 auto 35px; }
  @media (max-width: 767px) {
    .section-certification {
      padding: 80px 0; } }

/* ------------------------------------------------------------ *\
	Section Awards
\* ------------------------------------------------------------ */
.section-awards {
  padding: 100px 0; }
  .section-awards h6 {
    margin-bottom: 22px; }
  .section-awards .section__head {
    margin-bottom: 40px; }
  .section-awards .section__group + .section__group {
    margin-top: 80px; }
  .section-awards .section__foot {
    padding-top: 80px; }
  @media (max-width: 767px) {
    .section-awards {
      padding: 80px 0; }
      .section-awards h3 {
        margin-bottom: 20px; }
      .section-awards .section__group + .section__group {
        margin-top: 25px; }
      .section-awards .section__foot {
        padding-top: 25px; } }

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */
.slider-testimonial .swiper-pagination {
  left: auto;
  right: 0;
  width: auto;
  font-size: 0;
  padding-right: 4px; }

.slider-testimonial .swiper-pagination-bullet {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #888888;
  opacity: 1 !important;
  font-size: 0;
  margin: 0; }
  .slider-testimonial .swiper-pagination-bullet:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #24332d;
    visibility: hidden;
    opacity: 0; }

.slider-testimonial .swiper-pagination-bullet-active {
  background: #24332d;
  opacity: 1 !important; }
  .slider-testimonial .swiper-pagination-bullet-active:before {
    visibility: visible;
    opacity: 1; }

.slider-testimonial .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0; }
  .slider-testimonial .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 26px; }

@media (max-width: 767px) {
  .slider-testimonial .swiper-pagination {
    position: static;
    padding: 41px 5px 5px;
    text-align: left; } }

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	table-awards
\* ------------------------------------------------------------ */
.table-awards .table__row {
  display: flex;
  align-items: center;
  padding: 16px 0; }

.table-awards .table__body .table__row {
  border-top: 1px solid rgba(36, 51, 45, 0.12); }

.table-awards .table__head strong {
  font-size: 10px;
  color: #888888;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase; }

.table-awards h5,
.table-awards p {
  margin-bottom: 0; }

.table-awards .cell-1 {
  width: 40%; }

.table-awards .cell-2 {
  width: 13%; }

.table-awards .cell-3 {
  width: 15%; }

.table-awards .cell-4 {
  width: 32%; }

@media (max-width: 767px) {
  .table-awards .table__row {
    flex-wrap: wrap;
    padding: 15px 0; }
  .table-awards .cell-1,
  .table-awards .cell-4 {
    width: 100%; }
  .table-awards .cell-1 {
    order: 2; }
  .table-awards .cell-2 {
    order: 0; }
  .table-awards .cell-3 {
    order: 1;
    padding-left: 10px; }
  .table-awards .cell-4 {
    order: 3; } }

/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */
.socials {
  color: #115b3f;
  font-size: 17px; }
  .socials a {
    transition: color 0.4s; }
    .socials a:hover {
      color: #24332d; }
  .socials ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0 -10px; }
  .socials li {
    padding: 0 10px; }

/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */
.widgets {
  list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */
/* ------------------------------------------------------------ *\
	Intro-video
\* ------------------------------------------------------------ */
.intro-video {
  position: relative;
  z-index: 1; }
  .intro-video svg {
    font-size: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99; }

/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */
.intro {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 684px;
  background-size: cover;
  background-position: center center;
  text-align: center; }
  .intro--overlap {
    align-items: flex-start;
    padding-top: 130px; }
  .intro:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(36, 51, 45, 0.4); }
  .intro h5 {
    font-weight: 700;
    font-family: "BebasNeue", sans-serif;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 34px;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    z-index: 2; }
  .intro h1 {
    max-width: 800px;
    color: #fff;
    position: relative;
    z-index: 2; }
  @media (max-width: 1023px) {
    .intro {
      height: 576px; }
      .intro h1 {
        padding: 0 10px; } }
  @media (max-width: 767px) {
    .intro {
      height: 468px; }
      .intro--overlap {
        align-items: center;
        padding-top: 0px; } }

/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */
.video {
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden; }
  .video--square {
    padding-bottom: 100%; }
  @media (max-width: 767px) {
    .video.normal-on-mobile {
      min-height: 0; } }

.video .text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }
  @media (max-width: 767px) {
    .video .text-overlay.text-overlay-top-on-mobile {
      transform: translate(-50%, -160px); } }

.video iframe,
.video .video__inner,
.video .video__placeholder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.video .video__inner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  z-index: 2; }

.video .video__placeholder {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1; }

.play {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 179px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
  cursor: pointer;
  pointer-events: auto;
  background: #a6a6a6;
  box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14);
  transform: translate(-50%, -50%);
  transition: background 0.4s;
  border: none; }
  .play i {
    font-size: 0; }
  .play--medium {
    width: 99px;
    height: 99px; }
  .play--reversed {
    border: 2px solid #fff;
    background: none; }
    .play--reversed:hover {
      background: #fff !important; }
      .play--reversed:hover svg path {
        fill: #24332d; }
  @media (max-width: 767px) {
    .play {
      height: 100px;
      width: 100px; }
      .play.small-on-mobile {
        width: 89px;
        height: 88px; } }

.play:hover {
  background: #888888; }

/*  Video Playing  */
.video.is-playing .video__inner {
  opacity: 1;
  visibility: visible; }

.video.is-playing .play {
  opacity: 0;
  pointer-events: none; }

.video.is-playing .text-overlay {
  visibility: hidden;
  opacity: 0; }

/* ------------------------------------------------------------ *\
	Background Video
\* ------------------------------------------------------------ */
.video-bg {
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden; }
  .video-bg video {
    max-width: none;
    max-height: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

/* ------------------------------------------------------------ *\
	video-trigger
\* ------------------------------------------------------------ */
.video-trigger {
  display: block;
  position: relative; }
  .video-trigger img {
    width: 100%;
    height: auto; }

/* ------------------------------------------------------------ *\
	Terms
\* ------------------------------------------------------------ */
.terms {
  padding-bottom: 143px;
  padding-top: 95px; }
  .terms p + h5 {
    margin-top: 40px; }

/* ------------------------------------------------------------ *\
	Page Title
\* ------------------------------------------------------------ */
.page-title {
  background: url(../images/header-bg.png) 0 0 repeat;
  min-height: 145px;
  display: flex;
  align-items: flex-end; }
  .page-title .shell {
    margin-top: auto;
    margin-bottom: 0; }
  .page-title h3 {
    color: #115b3f;
    margin-top: 0;
    margin-bottom: -45px; }

/* ------------------------------------------------------------ *\
	Card
\* ------------------------------------------------------------ */
.card {
  height: 377px;
  background-size: cover;
  background-position: center center; }
  .card i {
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: 22px;
    display: flex;
    align-items: center;
    transition: width 0.4s; }
    .card i .line {
      display: block;
      height: 1px;
      width: 100%;
      background: #fff; }
    .card i svg {
      display: block;
      margin-left: -9px; }
  .card h4 {
    color: #fff; }
  .card a {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    background: none;
    padding: 30px;
    transition: background 0.4s;
    text-decoration: none; }
    .card a:hover {
      background: rgba(36, 51, 45, 0.9); }
      .card a:hover i {
        width: calc(100% - 60px); }
  .card--1of3 {
    width: 33.33%; }
  @media (max-width: 767px) {
    .card--1of3 {
      width: 100%; } }

.cards {
  display: flex; }
  @media (max-width: 767px) {
    .cards {
      display: block; } }

/* ------------------------------------------------------------ *\
	Service
\* ------------------------------------------------------------ */
.service {
  display: flex;
  align-items: center; }
  .service h3 {
    margin-top: -30px;
    margin-bottom: 10px; }
  .service .media {
    width: 55%;
    flex-shrink: 0; }
  .service .list-features {
    max-width: 230px;
    margin-bottom: 34px; }
  .service .service__content {
    width: auto;
    padding-bottom: 76px;
    margin-left: calc(-100vw + 1340px);
    margin-right: -135px;
    padding-left: calc(100vw - 1340px);
    padding-right: 230px; }
  .service--reversed {
    flex-direction: row-reverse; }
    .service--reversed .service__content {
      margin-left: -135px;
      margin-right: calc(-100vw + 1340px);
      padding-left: 230px;
      padding-right: calc(100vw - 1340px); }
  .service + .service {
    margin-top: 158px; }
  @media (max-width: 1360px) {
    .service .service__content {
      margin-left: -20px;
      padding-left: 10px;
      margin-right: -50px;
      padding-right: -70px; }
    .service--reversed .service__content {
      margin-left: -50px;
      padding-left: 70px;
      margin-right: -20px;
      padding-right: 10px; } }
  @media (max-width: 1200px) {
    .service h3 {
      font-size: 70px; } }
  @media (max-width: 1023px) {
    .service .service__content {
      margin-left: -10px;
      margin-right: -50px;
      padding-right: 70px;
      padding-left: 20px; }
    .service--reversed .service__content {
      margin-right: -10px;
      margin-left: -50px;
      padding-left: 70px;
      padding-right: 20px; } }
  @media (max-width: 767px) {
    .service {
      display: block;
      margin: 0 -30px; }
      .service h3 {
        font-size: 64px; }
      .service .service__content {
        width: 100%;
        margin: 0;
        padding: 1px 30px 30px; }
      .service .media {
        width: 100%; }
      .service + .service {
        margin-top: 80px; } }

/* ------------------------------------------------------------ *\
	Brand
\* ------------------------------------------------------------ */
.brand-excerpt {
  display: flex; }
  .brand-excerpt h5 {
    color: #ffffff;
    line-height: 1.15; }
  .brand-excerpt .image {
    flex-shrink: 0; }
  .brand-excerpt .brands__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px; }

.brands-list {
  display: flex;
  flex-wrap: wrap; }
  .brands-list .brand-excerpt {
    width: calc(50% - 30px);
    margin: 15px 15px; }
  @media (max-width: 767px) {
    .brands-list {
      display: block; }
      .brands-list .brand-excerpt {
        width: 100%;
        margin: 0; }
      .brands-list .brand-excerpt + .brand-excerpt {
        margin-top: 30px; } }

/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */
.article-excerpt .article__media {
  box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14); }

.article-excerpt .article__image img {
  width: 100%;
  height: auto; }

.article-excerpt .article__content {
  padding: 60px 62px; }

.article-excerpt h5 {
  color: #115b3f;
  margin-bottom: 15px; }

@media (max-width: 767px) {
  .article-excerpt .article__content {
    padding: 34px 15px 40px; } }

.articles-excerpt-grid {
  display: flex; }
  .articles-excerpt-grid .article-excerpt {
    width: 33.33%; }
  @media (max-width: 1023px) {
    .articles-excerpt-grid {
      display: block; }
      .articles-excerpt-grid .article-excerpt {
        width: 100%; } }

/* ------------------------------------------------------------ *\
	article-horizontal
\* ------------------------------------------------------------ */
.article-horizontal {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse; }
  .article-horizontal h6 {
    margin-bottom: 12px; }
  .article-horizontal p {
    margin-bottom: 20px; }
  .article-horizontal .article__media {
    width: 61%;
    flex-shrink: 0; }
  .article-horizontal .article__content {
    width: 39%;
    padding: 74px 105px 0 0; }
  .article-horizontal + .article-horizontal {
    margin-top: 80px; }
  @media (max-width: 1023px) {
    .article-horizontal .article__content {
      padding: 0 30px 0 0; } }
  @media (max-width: 767px) {
    .article-horizontal {
      display: block; }
      .article-horizontal .article__content,
      .article-horizontal .article__media {
        width: 100%; }
      .article-horizontal .article__content {
        padding: 28px 0 0; } }

/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */
.testimonial h3 {
  margin-bottom: 20px; }

.testimonial h5 {
  color: #115b3f;
  margin-bottom: 0;
  line-height: 1; }

.testimonial small {
  font-family: "BebasNeue", sans-serif;
  font-weight: 700;
  font-size: 11px;
  color: #888888;
  letter-spacing: 1px;
  line-height: 1.82; }

/* ------------------------------------------------------------ *\
	Gallery
\* ------------------------------------------------------------ */
.gallery-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px; }
  .gallery-grid .gallery__item {
    max-width: 100%;
    flex: 0 0 100%;
    padding: 0 15px;
    margin-bottom: 30px; }
    .gallery-grid .gallery__item img {
      width: 100%;
      height: auto; }
    .gallery-grid .gallery__item:last-of-type {
      margin-bottom: 0; }
  .gallery-grid .gallery__item--1of2 {
    max-width: 50%;
    flex: 0 0 50%; }
  @media (max-width: 767px) {
    .gallery-grid .gallery__item {
      max-width: 100%;
      flex: 0 0 100%; } }

/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */
.paging {
  display: flex;
  align-items: center;
  justify-content: center; }
  .paging a {
    padding: 0 15px; }
  .paging ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none; }
    .paging ul a {
      font-family: BebasNeueBold;
      font-size: 26px;
      color: #115b3f;
      text-align: center;
      line-height: 26px;
      text-decoration: none;
      transition: color 0.4s; }
    .paging ul li {
      padding: 0 15px; }
    .paging ul a:hover,
    .paging ul .current a {
      color: #24332d; }
  @media (max-width: 767px) {
    .paging a {
      padding: 0 10px; }
    .paging ul li {
      padding: 0 10px; } }
