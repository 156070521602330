/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-open: 'OpenSans', sans-serif;
$font-bebas: 'BebasNeue', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;
$font-family-sans-serif: Arial, Helvetica, sans-serif;
$font-family-serif: Times, 'Times New Roman', serif;

$font-size-base: 16px;
$line-height-base: 1.56;

$shell-max-width: 1320px;
$shell-gutter: 10px;

$responsive-xxs: 374px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1200px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$retina: '(min-resolution: 2dppx) ';

// Usage
// @media #{$small-desktop}

$white: #fff;
$light-gray: #eef0ef;
$light-gray-alt: #f5f5f3;
$tan: #d1c9b4;
$tan-alt: #cec7b2;
$green: #115b3f;
$dark: #24332d;
$white-rock: #d8cfb9;
$grey: #888888;
$grey-dark: #a6a6a6;
$gainsboro: #e3e3e3;
