/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: relative;
	background: #d8cfb9;
	background: url(../images/header-bg.png) 0 0 repeat;
	text-align: center;
	z-index: 2;

	.header__aside {
		position: absolute;
		top: 50%;
		right: 10px;
		transform: translateY(-50%);

		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		.socials {
			padding: 0 20px;
		}
	}

	.nav {
	}

	.logo {
		position: absolute;
		left: 10px;
		top: 50%;
		transform: translateY(-50%);
	}

	.header__inner {
	}

	.shell {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.btn-menu {
		display: none;
	}

	@media #{$tablet-portrait} {
		height: 82px;
		display: flex;
		align-items: center;
		transition: background-image 0.4s;
		border-bottom: 1px solid rgba(36, 51, 45, 0.12);
		background-position: center center;

		.shell {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.btn-menu {
			display: block;
		}

		.logo {
			position: static;
			transform: none;
		}

		&--nav-open {
			z-index: 9;
			background: url(../images/header-nav-open.png) 0 0 repeat;
			background-position: center center;
		}

		.header__inner {
			position: fixed;
			top: 82px;
			left: 0;
			width: 100%;
			height: calc(100vh - 82px);
			background: url(../images/header-open-bg.png) 0 0 repeat;
			z-index: 9;
			padding: 40px 30px 30px;
			display: flex;
			flex-direction: column;
			visibility: hidden;
			opacity: 0;
			transition: visibility 0.4s, opacity 0.4s;

			&--open {
				visibility: visible;
				opacity: 1;
			}
		}

		.nav {
			height: calc(100vh - 180px);
			max-height: calc(100vh - 180px);
			overflow-y: auto;
			padding-bottom: 20px;
		}

		.header__aside {
			display: block;
			position: static;
			transform: none;
			padding-top: 20px;
			.btn {
				width: 100%;
				max-width: 315px;
				margin-bottom: 30px;
			}

			.socials {
				ul {
					justify-content: center;
				}
			}
		}
	}
}
