/* ------------------------------------------------------------ *\
	Video
\* ------------------------------------------------------------ */

.video {
	padding-bottom: 56.25%;
	position: relative;
	overflow: hidden;

	&--square {
		padding-bottom: 100%;
	}

	@media #{$mobile} {
		// min-height: 468px;

		&.normal-on-mobile {
			min-height: 0;
		}
	}
}

.video .text-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;

	@media #{$mobile} {
		&.text-overlay-top-on-mobile {
			transform: translate(-50%, -160px);
		}
	}
}

.video iframe,
.video .video__inner,
.video .video__placeholder {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.video .video__inner {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.4s ease, visibility 0.4s ease;
	z-index: 2;
}
.video .video__placeholder {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	z-index: 1;
}

.play {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 179px;
	height: 179px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 3;
	cursor: pointer;
	pointer-events: auto;
	background: #a6a6a6;
	box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14);
	transform: translate(-50%, -50%);
	transition: background 0.4s;
	border: none;

	i {
		font-size: 0;
	}

	&--medium {
		width: 99px;
		height: 99px;
	}

	&--reversed {
		border: 2px solid #fff;
		background: none;

		&:hover {
			background: $white !important;

			svg path {
				fill: #24332d;
			}
		}
	}

	@media #{$mobile} {
		height: 100px;
		width: 100px;

		&.small-on-mobile {
			width: 89px;
			height: 88px;
		}
	}
}

.play:hover {
	background: $grey;
}

/*  Video Playing  */

.video.is-playing .video__inner {
	opacity: 1;
	visibility: visible;
}
.video.is-playing .play {
	opacity: 0;
	pointer-events: none;
}
.video.is-playing .text-overlay {
	visibility: hidden;
	opacity: 0;
}

/* ------------------------------------------------------------ *\
	Background Video
\* ------------------------------------------------------------ */

.video-bg {
	position: relative;
	height: 0;
	padding-top: 56.25%;
	overflow: hidden;

	video {
		max-width: none;
		max-height: none;
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

/* ------------------------------------------------------------ *\
	video-trigger
\* ------------------------------------------------------------ */

.video-trigger {
	display: block;
	position: relative;

	img {
		width: 100%;
		height: auto;
	}
}
