/* ------------------------------------------------------------ *\
	Card
\* ------------------------------------------------------------ */

.card {
	height: 377px;
	background-size: cover;
	background-position: center center;

	i {
		position: absolute;
		bottom: 30px;
		left: 30px;
		width: 22px;
		display: flex;
		align-items: center;
		transition: width 0.4s;

		.line {
			display: block;
			height: 1px;
			width: 100%;
			background: #fff;
		}

		svg {
			display: block;
			margin-left: -9px;
		}
	}

	h4 {
		color: #fff;
	}

	a {
		position: relative;
		height: 100%;
		display: flex;
		align-items: center;
		background: none;
		padding: 30px;
		transition: background 0.4s;
		text-decoration: none;

		&:hover {
			background: rgba(36, 51, 45, 0.9);

			i {
				width: calc(100% - 60px);
			}
		}
	}

	&--1of3 {
		width: 33.33%;
	}

	@media #{$mobile} {
		&--1of3 {
			width: 100%;
		}
	}
}

.cards {
	display: flex;

	@media #{$mobile} {
		display: block;
	}
}
