/* ------------------------------------------------------------ *\
	Testimonial
\* ------------------------------------------------------------ */

.testimonial {
	h3 {
		margin-bottom: 20px;
	}

	h5 {
		color: #115b3f;
		margin-bottom: 0;
		line-height: 1;
	}

	small {
		font-family: $font-bebas;
		font-weight: 700;
		font-size: 11px;
		color: #888888;
		letter-spacing: 1px;
		line-height: 1.82;
	}
}
