/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	max-width: $shell-max-width + $shell-gutter * 2;
	padding-right: $shell-gutter;
	padding-left: $shell-gutter;
	margin: auto;
	// width: 80%;

	&--fluid {
		max-width: none;
	}

	&--small {
		max-width: 825px;
	}

	&--medium {
		max-width: 1014px;
	}

	@media #{$tablet-portrait} {
		width: 85%;
		max-width: 100%;

		&--small {
			width: 100%;
			max-width: 100%;
		}
	}

	@media #{$mobile} {
		width: 90%;
		width: 100%;
		max-width: 100%;
		padding-left: 30px;
		padding-right: 30px;
	}
}
