/* ------------------------------------------------------------ *\
	Gallery
\* ------------------------------------------------------------ */

.gallery-grid {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -15px;

	.gallery__item {
		max-width: 100%;
		flex: 0 0 100%;
		padding: 0 15px;
		margin-bottom: 30px;

		img {
			width: 100%;
			height: auto;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.gallery__item--1of2 {
		max-width: 50%;
		flex: 0 0 50%;
	}

	@media #{$mobile} {
		.gallery__item {
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}
