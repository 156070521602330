/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
	font-family: 'BebasNeue';
	src: url('../fonts/BebasNeueBold.woff') format('woff'),
		 url('../fonts/BebasNeueBold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg'),
       url('../fonts/OpenSans-Light.woff') format('woff'),
       url('../fonts/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg'),
       url('../fonts/OpenSans-Regular.woff') format('woff'),
       url('../fonts/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Italic.eot');
  src: url('../fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg'),
       url('../fonts/OpenSans-Italic.woff') format('woff'),
       url('../fonts/OpenSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg'),
       url('../fonts/OpenSans-Bold.woff') format('woff'),
       url('../fonts/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg'),
       url('../fonts/Montserrat-Bold.woff') format('woff'),
       url('../fonts/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

