/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::placeholder {
	color: inherit;
	opacity: 1;
}

textarea::placeholder {
	color: inherit;
	opacity: 1;
}

input:-webkit-autofill {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: 0 0 0 1000px #fff inset;
}

.form__label {
	display: block;
	font-weight: 700;
	font-size: 10px;
	color: $grey;
	letter-spacing: 1px;
	line-height: 16px;
	text-transform: uppercase;
	margin-bottom: 5px;
}

.field,
.textarea {
	background: #f5f5f5;
	font-weight: 400;
	font-size: 16px;
	color: $dark;
	border: none;
	border-radius: 0;
	width: 100%;

	&::placeholder {
		color: $dark;
	}
}

.field {
	height: 41px;
	padding: 0 12px;
}

.textarea {
	display: block;
	height: 116px;
	padding: 8px 12px;
}

.form__actions {
	padding-top: 10px;
}

.form__controls {
	margin-bottom: 20px;
}

.form__col {
	max-width: 100%;
	flex: 1;
	padding: 0 10px;

	&--1of2 {
		max-width: 50%;
		flex: 0 0 50%;
	}
}

.form__row {
	display: flex;
	flex-flow: row wrap;
	margin: 0 -10px;
}

.select {
}

.radio {
}

.checkbox {
}
