/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
}

/* ------------------------------------------------------------ *\
	form-contact
\* ------------------------------------------------------------ */

.form-contact {
	padding: 40px 74px 60px;
	background: #fff;
	box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14);

	&.pull-top {
		position: relative;
		margin-top: -277px;
		z-index: 3;
	}

	@media #{$tablet-portrait} {
		padding: 30px;
	}

	@media #{$mobile} {
		&.pull-top {
			padding-bottom: 40px;
			margin: 0 -30px;
			box-shadow: none;
		}

		.form__col {
			width: 100%;
			max-width: 100%;
			flex: 0 0 100%;
		}
	}
}
