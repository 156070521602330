/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear {
	@util clearfix;
}

/*  Notext  */

.notext {
	@util text-hide;
}

/*  Hidden  */

[hidden],
.hidden {
	display: none !important;
}

/*  Alignleft  */

.alignleft {
	float: left;
}

/*  Alignright  */

.alignright {
	float: right;
}

.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}

/*  Disabled  */

[disabled],
.disabled {
	cursor: default;
}

/*  Grid  */

.col {
	max-width: 100%;
	flex: 0 0 100%;

	&s {
		display: flex;
		flex-flow: row wrap;
	}

	&--1of2 {
		max-width: 50%;
		flex: 0 0 50%;
	}
}

.cols--large-spacing {
	margin: 0 -30px;

	.col {
		padding: 0 30px;
	}
}

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
	display: none !important;
}

@media (max-width: $responsive-xs) {
	.hidden-xs {
		display: none !important;
	}
	.visible-xs-block {
		display: block !important;
	}
	.visible-xs-inline {
		display: inline !important;
	}
	.visible-xs-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: $responsive-xs + 1) and (max-width: $responsive-sm) {
	.hidden-sm {
		display: none !important;
	}
	.visible-sm-block {
		display: block !important;
	}
	.visible-sm-inline {
		display: inline !important;
	}
	.visible-sm-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: $responsive-sm + 1) and (max-width: $responsive-md) {
	.hidden-md {
		display: none !important;
	}
	.visible-md-block {
		display: block !important;
	}
	.visible-md-inline {
		display: inline !important;
	}
	.visible-md-inline-block {
		display: inline-block !important;
	}
}

@media (min-width: $responsive-md + 1) {
	.hidden-lg {
		display: none !important;
	}
	.visible-lg-block {
		display: block !important;
	}
	.visible-lg-inline {
		display: inline !important;
	}
	.visible-lg-inline-block {
		display: inline-block !important;
	}
}

/* ------------------------------------------------------------ *\
	Shadows
\* ------------------------------------------------------------ */

.shadow-sm {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.shadow-med {
	box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14);
}
.shadow-med-right {
	box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2), 0 8px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 8px 0 rgba(0, 0, 0, 0.14);
}

.shadow-lg {
	box-shadow: 0 45px 90px 0 rgba(0, 0, 0, 0.2), 0 15px 30px 0 rgba(0, 0, 0, 0.12), 0 5px 10px 0 rgba(0, 0, 0, 0.14);
}
