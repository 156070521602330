/* ------------------------------------------------------------ *\
	Widgets
\* ------------------------------------------------------------ */

.widgets {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget {
}
