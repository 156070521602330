/* ------------------------------------------------------------ *\
	Paging
\* ------------------------------------------------------------ */

.paging {
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		padding: 0 15px;
	}

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style-type: none;

		a {
			font-family: BebasNeueBold;
			font-size: 26px;
			color: #115b3f;
			text-align: center;
			line-height: 26px;
			text-decoration: none;
			transition: color 0.4s;
		}

		li {
			padding: 0 15px;
		}

		a:hover,
		.current a {
			color: $dark;
		}
	}
	.paging__prev {
	}
	.paging__next {
	}
	@media #{$mobile} {
		a {
			padding: 0 10px;
		}

		ul li {
			padding: 0 10px;
		}
	}
}
