/* ------------------------------------------------------------ *\
	Service
\* ------------------------------------------------------------ */

.service {
	display: flex;
	align-items: center;

	h3 {
		margin-top: -30px;
		margin-bottom: 10px;
	}

	.media {
		width: 55%;
		flex-shrink: 0;
	}

	.list-features {
		max-width: 230px;
		margin-bottom: 34px;
	}

	.service__content {
		width: auto;
		padding-bottom: 76px;
		margin-left: calc(-100vw + 1340px);
		margin-right: -135px;
		padding-left: calc(100vw - 1340px);
		padding-right: 230px;
	}

	&--reversed {
		flex-direction: row-reverse;

		.service__content {
			margin-left: -135px;
			margin-right: calc(-100vw + 1340px);
			padding-left: 230px;
			padding-right: calc(100vw - 1340px);
		}
	}

	& + .service {
		margin-top: 158px;
	}

	@media (max-width: 1360px) {
		.service__content {
			margin-left: -20px;
			padding-left: 10px;
			margin-right: -50px;
			padding-right: -70px;
		}
		&--reversed {
			.service__content {
				margin-left: -50px;
				padding-left: 70px;
				margin-right: -20px;
				padding-right: 10px;
			}
		}
	}

	@media #{$small-desktop} {
		h3 {
			font-size: 70px;
		}
	}

	@media #{$tablet-portrait} {
		.service__content {
			margin-left: -10px;
			margin-right: -50px;
			padding-right: 70px;
			padding-left: 20px;
		}

		&--reversed .service__content {
			margin-right: -10px;
			margin-left: -50px;
			padding-left: 70px;
			padding-right: 20px;
		}
	}

	@media #{$mobile} {
		display: block;
		margin: 0 -30px;

		h3 {
			font-size: 64px;
		}

		.service__content {
			width: 100%;
			margin: 0;
			padding: 1px 30px 30px;
		}

		.media {
			width: 100%;
		}

		& + .service {
			margin-top: 80px;
		}
	}
}
