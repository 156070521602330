/* ------------------------------------------------------------ *\
	Page Title
\* ------------------------------------------------------------ */

.page-title {
	background: url(../images/header-bg.png) 0 0 repeat;
	min-height: 145px;
	display: flex;
	align-items: flex-end;

	.shell {
		margin-top: auto;
		margin-bottom: 0;
	}

	h3 {
		color: $green;
		margin-top: 0;
		margin-bottom: -45px;
	}
}
