/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
	color: #115b3f;
	font-size: 17px;

	a {
		transition: color 0.4s;

		&:hover {
			color: $dark;
		}
	}

	ul {
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0 -10px;
	}

	li {
		padding: 0 10px;
	}
}
