/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^='list-'] {
	list-style: none outside none;
}

/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links {
	font-weight: 700;
	font-size: 13px;
	color: $green;
	letter-spacing: 1.25px;

	a {
		text-decoration: none;
		text-transform: uppercase;
		transition: color 0.4s;

		&:hover {
			color: $dark;
		}
	}

	li {
		margin-bottom: 12px;
	}
}

/* ------------------------------------------------------------ *\
	List Links Alt
\* ------------------------------------------------------------ */

.list-links-alt {
	margin-bottom: 0;
	a {
		display: block;
		font-size: 14px;
		color: $dark;
		line-height: 1.71;
		text-decoration: none;

		&:hover {
			color: $green;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Clients
\* ------------------------------------------------------------ */

.list-clients {
	display: flex;
	flex-wrap: wrap;

	li {
		width: 20%;
		text-align: center;
		padding: 38px 0;
	}

	a {
		font-size: 77px;
		line-height: 1;
		color: #115b3f;
	}

	@media #{$mobile} {
		li {
			width: 50%;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Features
\* ------------------------------------------------------------ */

.list-features {
	margin-bottom: 0;
	li {
		display: flex;
		align-items: center;
	}

	li + li {
		margin-top: 24px;
	}

	span {
		font-weight: 700;
		font-size: 16px;
		color: $dark;
		letter-spacing: 0.5px;
		line-height: 1.25;
	}

	i {
		width: 63px;
		flex-shrink: 0;
		font-size: 0;
	}
}

/* ------------------------------------------------------------ *\
	List btns
\* ------------------------------------------------------------ */

.list-btns {
	display: flex;
	justify-content: center;
	align-items: center;

	li {
		width: 50%;
		padding: 0 5px;
	}

	.btn {
		width: 100%;
	}

	@media #{$mobile} {
		display: block;
		li {
			width: 100%;
		}

		li + li {
			margin-top: 10px;
		}

		.btn {
			min-width: 180px;
		}
	}
}

/* ------------------------------------------------------------ *\
	list-capabilities
\* ------------------------------------------------------------ */

.list-capabilities {
	a {
		font-family: $font-bebas;
		font-weight: 700;
		font-size: 26px;
		line-height: 1.15;
		color: #ffffff;
		text-transform: uppercase;
		text-decoration: none;
	}

	li {
		padding: 13px 0;
	}

	li + li {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}
}

/* ------------------------------------------------------------ *\
	list-features-square
\* ------------------------------------------------------------ */

.list-features-square {
	display: flex;
	margin: 0 -5px;

	h5 {
		margin-bottom: 15px;
		color: $green;
	}

	li {
		width: calc(33.33% - 10px);
		margin: 0 5px;
		padding: 50px 57px 50px;
		background: url(../images/feature-tile.jpg) 0 0 no-repeat;
		background-size: cover;
	}

	@media #{$tablet-portrait} {
		li {
			padding: 30px 30px 30px;
		}
	}

	@media #{$mobile} {
		display: block;
		margin: 0 -30px;

		li {
			padding: 0;
			margin: 0;
			width: 100%;
			padding: 50px 45px;
		}

		li + li {
			margin-top: 10px;
		}
	}
}

/* ------------------------------------------------------------ *\
	list-certification
\* ------------------------------------------------------------ */

.list-certification {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin: 0 -10px;

	li {
		width: 33.33%;
		padding: 15px 10px;
		text-align: center;
	}
	@media #{$mobile} {
		display: block;
		li {
			width: 100%;
			padding: 0;
		}

		li + li {
			margin-top: 40px;
		}
	}
}
