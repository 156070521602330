/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	position: relative;
	padding: 80px 0 50px;
	background: url(../images/footer-bg.jpg) 0 0 no-repeat;
	background-size: cover;
	background-position: center center;

	h4 {
		margin-bottom: 14px;
	}

	.footer__logo {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}

	.footer-cols {
		display: flex;
	}

	.footer__col {
		width: 25%;
	}

	@media #{$tablet-portrait} {
		.footer-cols {
			flex-wrap: wrap;
		}
		.footer__col {
			width: 50%;
		}
	}

	@media #{$mobile} {
		padding: 55px 0 25px;

		.footer__logo {
			min-width: 275px;
		}

		.footer__col {
			padding: 25px 0;
		}
		.footer__col:nth-child(1) {
			order: 3;
		}
		.footer__col:nth-child(2) {
			order: 2;
		}
		.footer__col:nth-child(3) {
			order: 0;
		}
		.footer__col:nth-child(4) {
			order: 1;
		}
	}
}
