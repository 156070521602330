/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {
}

/* ------------------------------------------------------------ *\
	table-awards
\* ------------------------------------------------------------ */

.table-awards {
	.table__row {
		display: flex;
		align-items: center;
		padding: 16px 0;
	}

	.table__body {
		.table__row {
			border-top: 1px solid rgba(36, 51, 45, 0.12);
		}
	}

	.table__head strong {
		font-size: 10px;
		color: #888888;
		letter-spacing: 1px;
		line-height: 16px;
		text-transform: uppercase;
	}

	h5,
	p {
		margin-bottom: 0;
	}

	.cell-1 {
		width: 40%;
	}
	.cell-2 {
		width: 13%;
	}
	.cell-3 {
		width: 15%;
	}
	.cell-4 {
		width: 32%;
	}

	@media #{$mobile} {
		.table__row {
			flex-wrap: wrap;
			padding: 15px 0;
		}

		.cell-1,
		.cell-4 {
			width: 100%;
		}

		.cell-1 {
			order: 2;
		}
		.cell-2 {
			order: 0;
		}
		.cell-3 {
			order: 1;
			padding-left: 10px;
		}
		.cell-4 {
			order: 3;
		}
	}
}
