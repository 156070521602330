/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
	> ul {
		display: flex;
		align-items: center;
	}

	> ul > li {
		margin: 0 30px;
	}

	> ul > li > a {
		position: relative;
		display: block;
		padding: 32px 0;
		font-weight: 700;
		font-size: 13px;
		color: #115b3f;
		letter-spacing: 1.25px;
		text-align: center;
		text-transform: uppercase;
	}

	> ul > li > a:hover,
	.current > a {
		&:after {
			position: absolute;
			bottom: -1px;
			left: 0;
			content: '';
			width: 100%;
			height: 3px;
			background: $green;
			z-index: 2;
		}
	}

	a {
		text-decoration: none;
	}

	.parent {
		position: relative;
	}

	.dropdown {
		position: absolute;
		left: 0;
		top: calc(100% - 20px);
		background: #fff;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
		border-radius: 1px;
		width: 220px;
		visibility: hidden;
		opacity: 0;
		z-index: 3;

		a {
			display: block;
			font-weight: 700;
			font-size: 13px;
			color: $green;
			letter-spacing: 1.25px;
			height: 48px;
			line-height: 48px;
			padding: 0 10px 0 30px;
			background: #fff;
			text-align: left;

			&:hover {
				background: $gainsboro;
			}
		}
	}

	.parent:hover,
	.dropdown--open {
		.dropdown {
			visibility: visible;
			opacity: 1;
		}
	}

	@media #{$small-desktop} {
		> ul > li {
			margin: 0 10px;
		}
	}

	@media #{$tablet-portrait} {
		> ul {
			display: block;
		}
		> ul > li {
			margin: 0;
		}
		> ul > li > a {
			text-align: left;
			padding: 11px 0;
			font-family: $font-bebas;
			font-weight: 700;
			font-size: 28px;
			color: #24332d;
			line-height: 1;
			&:after {
				display: none;
			}
		}

		.parent {
			> a {
				position: relative;
				font-family: $font-montserrat;
				font-weight: 700;
				font-size: 10px;
				color: #888888;
				letter-spacing: 1px;
				line-height: 16px;
				border-bottom: 1px solid rgba(36, 51, 45, 0.12);
			}

			svg {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				transition: transform 0.4s;
			}
		}

		.dropdown {
			position: static;
			visibility: visible !important;
			opacity: 1 !important;
			background: none;
			border: none;
			width: 100%;
			box-shadow: none;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.4s;
		}

		.dropdown a {
			position: relative;
			background: none;
			padding-left: 30px;
			font-family: $font-bebas;
			font-weight: 700;
			font-size: 28px;
			text-transform: uppercase;
			color: #24332d;

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				content: '';
				background: $grey;
				width: 20px;
				height: 2px;
				transform: translateY(-50%);
			}
		}

		.dropdown--open .dropdown {
			max-height: 500px;
		}

		.dropdown--open > a svg {
			transform: rotate(180deg) translateY(50%);
		}
	}
}
