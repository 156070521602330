/* ------------------------------------------------------------ *\
	Brand
\* ------------------------------------------------------------ */

.brand-excerpt {
	display: flex;

	h5 {
		color: #ffffff;
		line-height: 1.15;
	}

	.image {
		flex-shrink: 0;
	}

	.brands__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 20px;
	}
}

.brands-list {
	display: flex;
	flex-wrap: wrap;

	.brand-excerpt {
		width: calc(50% - 30px);
		margin: 15px 15px;
	}

	@media #{$mobile} {
		display: block;
		.brand-excerpt {
			width: 100%;
			margin: 0;
		}

		.brand-excerpt + .brand-excerpt {
			margin-top: 30px;
		}
	}
}
