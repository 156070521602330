/* ------------------------------------------------------------ *\
	Intro-video
\* ------------------------------------------------------------ */

.intro-video {
	position: relative;
	z-index: 1;
	svg {
		font-size: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
	}
}

/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 684px;
	background-size: cover;
	background-position: center center;
	text-align: center;

	&--overlap {
		align-items: flex-start;
		padding-top: 130px;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		background: rgba(36, 51, 45, 0.4);
	}

	h5 {
		font-weight: 700;
		font-family: $font-bebas;
		font-size: 22px;
		text-transform: uppercase;
		margin-bottom: 34px;
		color: rgba(255, 255, 255, 0.7);
		position: relative;
		z-index: 2;
	}

	h1 {
		max-width: 800px;
		color: #fff;
		position: relative;
		z-index: 2;
	}

	@media #{$tablet-portrait} {
		height: 576px;
		h1 {
			padding: 0 10px;
		}
	}

	@media #{$mobile} {
		height: 468px;

		&--overlap {
			align-items: center;
			padding-top: 0px;
		}
	}
}
